/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import tw, { css, theme } from "twin.macro";
import { AppContext } from "~context/AppContext.jsx";
import { CacheContext } from "~context/CacheContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import * as Logo from "~components/svg/Logos.jsx";
import Button from "~components/Button.jsx";
import DiamondGrid from "~components/DiamondGrid.jsx";
import DoubleSlider from "~components/DoubleSlider.jsx";
import FilterSelect from "~components/FilterSelect.jsx";
import Grid from "~components/styles/Grid.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import Tip from "~components/Tip.jsx";
import Video from "~components/Video.jsx";

import leaf from "~assets/images/leaf.svg";
import ladyMirror from "~assets/images/lady-mirror.svg";

// delay after initial diamond load
const LOAD_TIMEOUT = 2500;

// delay after switching from filters to results
const SEARCH_TIMEOUT = 3000;

// the time taken to show the loader on a filter change
const RENDER_TIMEOUT = 500;

const IndexPage = ({ data, location }) => {
  // =============================================================================
  // react

  const { resetSearch, setResetSearch, resultCount } = useContext(AppContext);
  const { enriched } = useContext(CacheContext);
  const { isDesktop, screen, scrollTop } = useContext(DocumentContext);

  const instasearchRef = useRef();
  const mobileGridRef = useRef();
  const scrollThresholdRef = useRef();

  const [activeFilters, setActiveFilters] = useState({});
  const [activeFilterTray, setActiveFilterTray] = useState(null);
  const [advancedVisible, setAdvancedVisible] = useState(false);
  const [expandedFilters, setExpandedFilters] = useState([]);
  const [gridRefreshing, setGridRefreshing] = useState(false);
  const [gridVisible, setGridVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterExclusions, setFilterExclusions] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [runSearch, setRunSearch] = useState(false);
  const [sorting, setSorting] = useState(null);
  const [tip, setTip] = useState({});
  const [tipActive, setTipActive] = useState(false);
  const [trayLoading, setTrayLoading] = useState(false);

  // =============================================================================
  // sanity

  const cms = data.sanityIndex;

  // =============================================================================
  // component data

  const availableFilters = {
    type: {
      key: `type`,
      widgetRef: useRef(),
      align: `right`,
      values: [`Natural Diamond`, `Lab Diamond`, `Moissanite`],
      type: `select`,
      tip: {
        image: null,
        imageXS: null,
        description: `Natural diamonds are the original diamond, formed underground by mother nature. Lab grown diamonds are the same as natural diamonds, but were grown in a lab. Moissanite is not a diamond, but looks similar, costs less, and is grown in a lab.`
      }
    },
    price: {
      key: `price`,
      widgetRef: useRef(),
      align: `left`,
      min: 0,
      max: 500000,
      prefix: `$`,
      step: 500,
      type: `slider`
    },
    carat: {
      key: `carat`,
      widgetRef: useRef(),
      align: `right`,
      min: 0,
      max: 1300,
      prefix: `ct`,
      stringParser: (valueStr) =>
        parseFloat(valueStr / 100)
          .toFixed(2)
          .toString(),
      type: `slider`,
      tip: {
        image: `/images/tips/carat.png`,
        imageXS: `/images/tips/carat.png`,
        description: `Carat is a measure of a diamond's weight, which also determines its size.`
      }
    },
    shape: {
      key: `shape`,
      widgetRef: useRef(),
      align: `left`,
      values: [
        {
          name: `Round`,
          icon: (
            <Icon.Round
              selected={filters?.shape?.includes(`Round`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Oval`,
          icon: (
            <Icon.Oval
              selected={filters?.shape?.includes(`Oval`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Cushion`,
          icon: (
            <Icon.Cushion
              selected={filters?.shape?.includes(`Cushion`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Emerald`,
          icon: (
            <Icon.Emerald
              selected={filters?.shape?.includes(`Emerald`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Asscher`,
          icon: (
            <Icon.Asscher
              selected={filters?.shape?.includes(`Asscher`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Pear`,
          icon: (
            <Icon.Pear
              selected={filters?.shape?.includes(`Pear`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Rose Cut`,
          icon: (
            <Icon.RoseCut
              selected={filters?.shape?.includes(`Rose Cut`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Marquise`,
          icon: (
            <Icon.Marquise
              selected={filters?.shape?.includes(`Marquise`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Trillion`,
          icon: (
            <Icon.Trillion
              selected={filters?.shape?.includes(`Trillion`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Old European`,
          icon: (
            <Icon.OldEuropean
              selected={filters?.shape?.includes(`Old European`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Old Mine`,
          icon: (
            <Icon.OldMine
              selected={filters?.shape?.includes(`Old Mine`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Radiant`,
          icon: (
            <Icon.Radiant
              selected={filters?.shape?.includes(`Radiant`)}
              styles={[tw`h-8`]}
            />
          )
        },
        {
          name: `Princess`,
          icon: (
            <Icon.Princess
              selected={filters?.shape?.includes(`Princess`)}
              styles={[tw`h-8`]}
            />
          )
        }
      ],
      type: `select`
    },
    cut: {
      key: `cut`,
      widgetRef: useRef(),
      align: `full`,
      values: [`Good`, `Very Good`, `Ideal`, `Super Ideal`, `All`],
      type: `select`,
      tip: {
        image: `/images/tips/cut.png`,
        imageXS: `/images/tips/cut.png`,
        description: `The higher the cut grade, the more sparkly the diamond will look. Poorly cut diamonds can look small for their size or dull. For the most sparkle, ideal cut is the best choice.`
      }
    },
    clarity: {
      key: `clarity`,
      widgetRef: useRef(),
      align: `full`,
      values: [
        `Salt and Pepper`,
        `SI2`,
        `SI1`,
        `VS2`,
        `VS1`,
        `VVS2`,
        `VVS1`,
        `IF`,
        `FL`
      ],
      type: `select`,
      tip: {
        image: `/images/tips/clarity.png`,
        imageXS: `/images/tips/clarity.png`,
        description: `Clarity is the absence of inclusions, small crystals inside of diamonds that block light. SI1 and SI2 diamonds may have inclusions visible to the naked eye. VS2 diamonds usually do not. VS1 and above are always eye-clean, meaning no inclusions are visible without magnification.`
      }
    },
    color: {
      key: `color`,
      widgetRef: useRef(),
      align: `full`,
      values: [
        `Any`,
        `L`,
        `K`,
        `J`,
        `I`,
        `H`,
        `G`,
        `F`,
        `E`,
        `D`,
        `All Fancy Colors`,
        `Fancy Pink`,
        `Fancy Blue`,
        `Fancy Yellow`
      ],
      type: `select`,
      tip: {
        image: `/images/tips/color.png`,
        imageXS: `/images/tips/color.png`,
        description: `The color grade measures how white a diamond is. K-M color diamonds have a more visible yellow hue. I-J color diamonds have a faint yellow hue, making them a good choice for yellow gold settings. G-H diamonds are near-colorless, a good value for white gold or platinum settings. D-F color diamonds are colorless, more rare, and more expensive.`
      }
    },
    polish: {
      key: `polish`,
      widgetRef: useRef(),
      align: `left`,
      values: [`Any`, `Good`, `Very Good`, `Excellent`],
      type: `select`,
      tip: {
        image: null,
        imageXS: null,
        description: `Polish is a measure of how smooth and even the surface finish of a diamond is.`
      }
    },
    symmetry: {
      key: `symmetry`,
      widgetRef: useRef(),
      align: `half`,
      values: [`Any`, `Good`, `Very Good`, `Excellent`],
      type: `select`,
      tip: {
        image: `/images/tips/symmetry.png`,
        imageXS: `/images/tips/symmetry.png`,
        description: `Symmetry is a measure of how identical one side of a diamond is to the other, and relates to how well a diamond is cut.`
      }
    },
    fluorescence: {
      key: `fluorescence`,
      widgetRef: useRef(),
      align: `half`,
      values: [`Any`, `None`, `Faint`, `Medium`, `Strong`, `Very Strong`],
      type: `select`,
      tip: {
        image: `/images/tips/fluorescence.png`,
        imageXS: `/images/tips/fluorescence.png`,
        description: `Fluorescence measures the appearance of a diamond under ultra violet light, including natural sunlight. Diamonds with flurorescence will appear blue under ultra violet light.`
      }
    },
    "fancy color intensity": {
      key: `fancy color intensity`,
      widgetRef: useRef(),
      align: `half`,
      values: [`Light`, `Fancy`, `Intense`, `Vivid`, `Deep`],
      type: `select`,
      tip: {
        image: null,
        imageXS: null,
        description: `A fancy color diamond becomes more valuable as the color intensity increases.`
      }
    },
    "crown angle": {
      key: `crown angle`,
      widgetRef: useRef(),
      align: `half`,
      min: 30,
      max: 40,
      suffix: `°`,
      type: `slider`
    },
    "l/w ratio": {
      key: `l/w ratio`,
      widgetRef: useRef(),
      align: `half`,
      min: 100,
      max: 200,
      stringParser: (valueStr) =>
        parseFloat(valueStr / 100)
          .toFixed(2)
          .toString(),
      suffix: ``,
      type: `slider`,
      tip: {
        image: `/images/tips/LW-ratio.png`,
        imageXS: `/images/tips/LW-ratio.png`,
        description: `A diamond's length width ratio is a measure of its overall shape.`
      }
    },
    table: {
      key: `table`,
      widgetRef: useRef(),
      align: `half`,
      min: 0,
      max: 100,
      suffix: `%`,
      type: `slider`,
      tip: {
        image: `/images/tips/table.png`,
        imageXS: `/images/tips/table.png`,
        description: `The table is the large flat surface at the top of the diamond. The table percentage is a measure of the table's width relative to the width of the diamond overall. It influences the diamond's cut grade. Select an ideal cut diamond for optimal table percentage.`
      }
    },
    depth: {
      key: `depth`,
      widgetRef: useRef(),
      align: `half`,
      min: 0,
      max: 100,
      suffix: `%`,
      type: `slider`,
      tip: {
        image: null,
        imageXS: null,
        description: `A diamond's depth (or height) is measured from the middle of the table to the culet (the point at the bottom). Depth percentage is depth divided by diamond width. This greatly influences light return and sparkle. Ideal cut diamonds will have optimal depth percentages.`
      }
    },
    "grading lab": {
      key: `grading lab`,
      widgetRef: useRef(),
      align: `full`,
      values: [`All`, `GIA`, `GCAL`, `IGI`],
      type: `select`
    },
    "supplier standards": {
      key: `supplier standards`,
      widgetRef: useRef(),
      align: `bottom`,
      values: [`Level 1`, `Level 2`, `Level 3`, `Level 4`],
      type: `select`,
      tip: {
        image: `/images/tips/supplier-standards-desktop.svg`,
        imageXS: `/images/tips/supplier-standards-mobile.svg`,
        description: `Each Frank Darling approved supplier must meet specific standards to qualify. These standards apply to both natural and lab diamonds.`
      }
    },
    "see in person": {
      key: `see in person`,
      widgetRef: useRef(),
      align: `bottom`,
      values: [`See IRL`, `See All`],
      type: `select`,
      tip: {
        image: null,
        imageXS: null,
        description: `Some diamonds are available to see in person at our showroom in Williamsburg, Brooklyn. Just <a href="https://consult.frankdarling.com/book/" rel="noopener noreferrer" target="_blank" class="underline">book an appointment</a> to come see them.`
      }
    }
  };

  const primaryFilter = availableFilters.type;
  const secondaryFilters = [
    availableFilters.price,
    availableFilters.carat,
    availableFilters.shape
  ];
  const specialFilters = [
    availableFilters[`see in person`],
    availableFilters[`supplier standards`]
  ];
  const tertiaryFilters = [];

  Object.keys(availableFilters).forEach((availableFilterKey) => {
    if (
      primaryFilter !== availableFilters[availableFilterKey] &&
      !secondaryFilters.includes(availableFilters[availableFilterKey]) &&
      !specialFilters.includes(availableFilters[availableFilterKey])
    ) {
      tertiaryFilters.push(availableFilters[availableFilterKey]);
    }
  });

  // =============================================================================
  // filter methods

  /**
   * -----------------------------------------------------------------------------
   * Apply state [filters] onto [activeFilters] and scroll the user to the grid.
   * @return {null}
   */
  const applyFilters = () => {
    if (!Object.keys(filters)?.[0]) {
      return;
    }

    setActiveFilters(filters);

    if (gridVisible) {
      setGridRefreshing(true);
    }

    if (window && isDesktop()) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  };

  /**
   * -----------------------------------------------------------------------------
   * [filters] state object mutation.
   * @param  {string} key    Filter identifier
   * @param  {array} value   Array containing all current filter values
   * @return {null}
   */
  const saveFilterState = (key, array) => {
    setFilters({
      ...filters,
      [key]: array
    });
  };

  /**
   * -----------------------------------------------------------------------------
   * Set a filter by key to a single string value, replacing its old value.
   * @param  {string} key    Filter identifier
   * @param  {string} value  Filter value set
   * @return {null}
   */
  const replaceFilter = (key, value) => {
    if (!filters?.[key]) {
      filters[key] = value;
    }

    let filter = JSON.parse(JSON.stringify(filters[key]));

    filter = value;

    saveFilterState(key, filter);
  };

  /**
   * -----------------------------------------------------------------------------
   * Add/remove a filter from its keyed [filters] array depending on existence.
   * @param  {string} key    Filter identifier
   * @param  {string} value  Filter value to add/remove
   * @return {null}
   */
  const toggleFilter = (key, value) => {
    if (!filters?.[key]) {
      filters[key] = [];
    }

    let filter = JSON.parse(JSON.stringify(filters[key]));

    if (filter.includes(value)) {
      filter.splice(filter.indexOf(value), 1);
    } else {
      filter.push(value);
    }

    switch (key) {
      case `color`:
        if (value === `Any`) {
          if (filter.includes(`Any`)) {
            filter = [`Any`];
          } else {
            filter = [];
          }
        } else {
          const adjustedFilter = JSON.parse(JSON.stringify(filter));
          const anyIndex = adjustedFilter?.indexOf(`Any`);

          if (anyIndex !== -1) {
            adjustedFilter.splice(anyIndex, 1);
          }

          if (filter !== adjustedFilter) {
            filter = adjustedFilter;
          }

          //

          if (value.includes(`Fancy`) && filter?.[0]) {
            if (value === `All Fancy Colors`) {
              if (filter.includes(`All Fancy Colors`)) {
                const removableValues = [
                  `Fancy Pink`,
                  `Fancy Blue`,
                  `Fancy Yellow`
                ];

                const fancyFilter = JSON.parse(JSON.stringify(filter));

                removableValues.forEach((removableColor) => {
                  const colorIndex = fancyFilter.indexOf(removableColor);

                  if (colorIndex !== -1) {
                    fancyFilter.splice(colorIndex, 1);
                  }
                });

                if (filter !== fancyFilter) {
                  filter = fancyFilter;
                }
              }
            } else if (filter.includes(`All Fancy Colors`)) {
              filter.splice(filter.indexOf(`All Fancy Colors`), 1);
            }
          }
        }

        break;

      case `cut`:
      case `grading lab`:
        if (value === `All`) {
          if (filter.includes(`All`)) {
            filter = [`All`];
          } else {
            filter = [];
          }
        } else {
          const adjustedFilter = JSON.parse(JSON.stringify(filter));
          const allIndex = adjustedFilter?.indexOf(`All`);

          if (allIndex !== -1) {
            adjustedFilter.splice(allIndex, 1);
          }

          if (filter !== adjustedFilter) {
            filter = adjustedFilter;
          }
        }

        break;

      case `fluorescence`:
      case `polish`:
      case `symmetry`:
        if (value === `Any`) {
          if (filter.includes(`Any`)) {
            filter = [`Any`];
          } else {
            filter = [];
          }
        } else {
          const adjustedFilter = JSON.parse(JSON.stringify(filter));
          const anyIndex = adjustedFilter?.indexOf(`Any`);

          if (anyIndex !== -1) {
            adjustedFilter.splice(anyIndex, 1);
          }

          if (filter !== adjustedFilter) {
            filter = adjustedFilter;
          }
        }

        break;

      default:
        break;
    }

    saveFilterState(key, filter);
  };

  // =============================================================================
  // helper methods

  /**
   * -----------------------------------------------------------------------------
   * Expand filter sections on the mobile device layout.
   * @return {null}
   */
  const expandFilterSection = (key) => {
    const currentlyExpanded = JSON.parse(JSON.stringify(expandedFilters));
    const expandedIndex = currentlyExpanded.indexOf(key);

    if (expandedIndex === -1) {
      currentlyExpanded.push(key);
    } else {
      currentlyExpanded.splice(expandedIndex, 1);
    }

    setExpandedFilters(currentlyExpanded);
  };

  /**
   * -----------------------------------------------------------------------------
   * Determines whether or not a filter should have a blue background based on
   * filters defined by the user.
   * @return {boolean}  To blue, or not to blue.
   */
  const hasValue = (templateFilter) => {
    const userFilter = filters?.[templateFilter?.key];

    if (!userFilter || filterExclusions?.includes(templateFilter?.key)) {
      return false;
    }

    return (
      userFilter?.[0] ||
      userFilter?.max !== templateFilter?.max ||
      userFilter?.min !== templateFilter?.min
    );
  };

  /**
   * -----------------------------------------------------------------------------
   * Clear only what is excluded by a type change.
   * @return {null}
   */
  const runResetAnimation = () => {
    setActiveFilterTray(null);
    setAdvancedVisible(false);

    setTrayLoading(true);

    setTimeout(() => {
      setTrayLoading(false);
    }, RENDER_TIMEOUT + 100);

    if (window && isDesktop()) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  };

  /**
   * -----------------------------------------------------------------------------
   * Start again, clearing the state [filters] & [activeFilters].
   * @return {null}
   */
  const reset = () => {
    setActiveFilters({});
    setFilters({});
    runResetAnimation();
  };

  // =============================================================================
  // hooks

  /**
   * -----------------------------------------------------------------------------
   * useEffect [] (onload)
   * Scroll the user to the top of the page.
   * @return {null}
   */
  useEffect(() => {
    if (window) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300);
    }

    setTimeout(() => {
      setLoaded(true);
    }, LOAD_TIMEOUT);
  }, []);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [enriched]
   * Hide the initial loader after a small delay once all diamonds are available.
   * @return {null}
   */
  // useEffect(() => {
  // if (enriched) {
  // setTimeout(() => {
  //   setLoaded(true);
  // }, LOAD_TIMEOUT);
  //   }
  // }, [enriched]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [filters]
   * If the filter bar is showing, assume the user set a filter with the tray, and
   * apply filter changes automatically.
   * @return {null}
   */
  useEffect(() => {
    if (isDesktop() && gridVisible) {
      applyFilters();
    }
  }, [filters]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [filters?.type]
   * Adjust which filters are excluded whenever the selected type is changed.
   * @return {null}
   */
  useEffect(() => {
    if (!filters?.type) {
      return;
    }

    let exclusions;

    switch (filters.type.toLowerCase()) {
      case `lab diamond`:
        exclusions = [`fluorescence`];

        break;

      case `moissanite`: {
        exclusions = [
          `cut`,
          `clarity`,
          `color`,
          `polish`,
          `symmetry`,
          `fluorescence`,
          `fancy color intensity`,
          `crown angle`,
          `l/w ratio`,
          `table`,
          `depth`,
          `grading lab`
        ];

        break;
      }

      case `natural diamond`:
      default:
        exclusions = [];
        break;
    }

    if (exclusions?.[0]) {
      const userFilters = JSON.parse(JSON.stringify(filters));
      const excludedFiltersToReset = [];

      if (Object.keys(userFilters)?.[0]) {
        Object.keys(userFilters).forEach((filterKey) => {
          if (
            filterKey !== `type` &&
            exclusions.includes(filterKey) &&
            userFilters?.[filterKey]?.[0]
          ) {
            excludedFiltersToReset.push(filterKey);
          }
        });
      }

      if (excludedFiltersToReset?.[0]) {
        excludedFiltersToReset.forEach((resetKey) => {
          userFilters[resetKey] = [];
        });
      }

      if (userFilters !== filters) {
        setFilters(userFilters);
      }
    }

    if (exclusions !== filterExclusions) {
      setFilterExclusions(exclusions);
    }
  }, [filters?.type]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [filterExclusions]
   * If the results are open and the type is changed, run bar reset animations.
   * @return {null}
   */
  useEffect(() => {
    if (gridVisible) {
      runResetAnimation();
    }
  }, [filterExclusions]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [filtersOpen]
   * Show/hide the advanced filters in the landing view.
   * @return {null}
   */
  useEffect(() => {
    if (
      !window ||
      !scrollThresholdRef?.current ||
      !filtersOpen ||
      !isDesktop()
    ) {
      return;
    }

    const top = scrollThresholdRef.current.offsetTop - 32;

    window.scrollTo({
      top,
      behavior: `smooth`
    });
  }, [filtersOpen]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [gridRefreshing]
   * Hide, show and animate the grid temporarily to allow a template redraw.
   * @return {null}
   */
  useEffect(() => {
    if (gridRefreshing) {
      setTimeout(() => {
        setGridRefreshing(false);
      }, RENDER_TIMEOUT);
    }
  }, [gridRefreshing]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [resetSearch]
   * Wipe state and take the user back to the initial landing filter page.
   * @return {null}
   */
  useEffect(() => {
    if (resetSearch) {
      setActiveFilters({});
      setActiveFilterTray(null);
      setAdvancedVisible(false);
      setExpandedFilters([]);
      setFilters({});
      setFiltersOpen(false);
      setGridVisible(false);
      setRunSearch(false);
      setGridRefreshing(false);
      setSorting(null);
      setTip({});
      setTipActive(false);

      setResetSearch(false);
    }
  }, [resetSearch]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [runSearch]
   * Triggers a move from the landing view to the diamond grid.
   * @return {null}
   */
  useEffect(() => {
    if (!isDesktop()) {
      applyFilters();
      setGridVisible(true);
    } else if (runSearch && !gridVisible) {
      applyFilters();

      setTimeout(() => {
        setGridVisible(true);
      }, parseInt(SEARCH_TIMEOUT * 0.3));
    }
  }, [runSearch]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [scrollTop]
   * Close any open trays whenever the user scrolls.
   * @return {null}
   */
  useEffect(() => {
    if (activeFilterTray) {
      setActiveFilterTray(null);
    }
  }, [scrollTop]);

  // =============================================================================
  // render

  const getFilterJSX = (filter, asWidget = false) => {
    const excluded =
      filter?.key !== `type` && filterExclusions?.includes(filter?.key);
    const primary = filter?.key?.toLowerCase() === `type`;

    let filterJSX;

    switch (filter?.type) {
      case `select`: {
        let onClick = ({ key, value }) => {
          toggleFilter(key, value);
        };

        if (primary) {
          onClick = ({ key, value }) => {
            replaceFilter(key, value);
          };
        }

        filterJSX = (
          <FilterSelect
            allFilters={filters}
            excluded={excluded}
            onClick={onClick}
            icons={filter?.icons || null}
            primary={primary}
            setTip={setTip}
            setTipActive={setTipActive}
            tip={filter.tip}
            title={filter.key}
            values={filter.values}
            widget={asWidget}
          />
        );

        break;
      }

      case `slider`:
        filterJSX = (
          <DoubleSlider
            active={hasValue(filter)}
            excluded={excluded}
            exponential={filter.key === `price`}
            min={filter.min}
            max={filter.max}
            onUpdate={({ key, value }) => {
              saveFilterState(key, value);
            }}
            syncWith={filters?.[filter?.key]}
            step={filter.step}
            stringParser={filter.stringParser}
            prefix={filter.prefix}
            suffix={filter.suffix}
            tip={filter.tip}
            title={filter.key}
            setTip={setTip}
            setTipActive={setTipActive}
            visible={asWidget && activeFilterTray === filter.key}
            widget={asWidget}
          />
        );

        break;

      default:
        filterJSX = <></>;
        break;
    }

    if (!isDesktop()) {
      const expanded =
        primary ||
        (expandedFilters?.[0] && expandedFilters.includes(filter.key));

      return (
        <article tw="col-span-24 relative">
          <div tw="w-full h-12 relative flex items-center justify-between pl-4 md:border-b border-silver text-white">
            <div tw="flex items-center">
              <T.Caption styles={[tw`uppercase`]}>{filter.key}</T.Caption>

              {expanded && filter?.tip && (
                <button
                  type="button"
                  css={[tw`w-5 h-5 relative block ml-2`]}
                  onClick={() => {
                    setTip({
                      ...filter.tip,
                      title: filter.key
                    });
                    setTipActive(true);
                  }}
                >
                  <div tw="w-full h-full flex items-center justify-center">
                    <Icon.Tip styles={[tw`h-3`]} />
                  </div>
                </button>
              )}
            </div>

            {!primary && (
              <button
                type="button"
                onClick={() => expandFilterSection(filter.key)}
                tw="w-12 h-12 relative z-10 block"
              >
                <div tw="w-12 h-12 relative flex items-center justify-end pr-4 pointer-events-none">
                  <Icon.Arrow
                    color="white"
                    styles={[
                      css`
                        transition: transform 0.3s ${A.EASING_CUBIC};
                        transform: rotate(${expanded ? `270deg` : `90deg`});
                        pointer-events: none !important;
                      `,
                      tw`w-2`
                    ]}
                  />
                </div>
              </button>
            )}
          </div>

          {expanded && (
            <div
              css={[
                css`
                  ${A.Keyframes(
                    `appearDown`,
                    `0.3s ${A.EASING_CUBIC} forwards`
                  )}
                `,
                tw`px-4`
              ]}
            >
              {filterJSX}
            </div>
          )}
        </article>
      );
    }

    return filterJSX;
  };

  const getWidgetJSX = (filter, leftOffset = 0) => {
    const active =
      activeFilterTray?.toLowerCase() === filter?.key?.toLowerCase();
    const excluded =
      filter?.key !== `type` && filterExclusions?.includes(filter?.key);

    let pointerEvents = `none`;

    if (active && !excluded) {
      pointerEvents = `auto`;
    }

    return (
      <div
        css={[
          css`
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            top: 72px;
            pointer-events: ${pointerEvents};
          `,
          tw`absolute z-40`
        ]}
      >
        <div
          css={[
            css`
              transition: opacity 0.15s ${A.EASING_CUBIC},
                transform 0.15s ${A.EASING_CUBIC};

              opacity: ${active ? `1` : `0`};
              transform: translate3d(0, ${active ? `0` : `-1.5rem`}, 0);

              width: 600px;

              // triangle
              &:before {
                content: " ";

                width: 0;
                height: 0;
                position: absolute;
                top: -1rem;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                border-style: solid;
                border-width: 0 1rem 1rem 1rem;
                border-color: transparent transparent #1c1794 transparent;
              }

              .filter-widget {
                transform: translate3d(${leftOffset}, 0, 0);
              }
            `,
            tw`relative`
          ]}
        >
          <div
            className="filter-widget"
            css={[tw`w-full relative px-3 bg-koamaru text-white`]}
          >
            {getFilterJSX(filter, true)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Tip
        active={tip && tipActive}
        title={tip.title}
        image={tip.image}
        imageXS={tip.imageXS}
        description={tip.description}
        setTipActive={setTipActive}
      />

      <Layout
        styles={[
          css`
            ${!loaded ? `height: 100vh; overflow: hidden;` : ``}
            pointer-events: ${!loaded || gridRefreshing ? `none` : `auto`};
            padding-top: 64px;

            @media screen and (min-width: 1025px) {
              padding-top: 120px;

              padding-bottom: ${instasearchRef?.current && !gridVisible
                ? instasearchRef.current.getBoundingClientRect().height
                : 0}px;
            }
          `
        ]}
      >
        <div
          id="loader"
          css={[
            css`
              transition: opacity 0.5s ${A.EASING_CUBIC};

              opacity: ${loaded ? 0 : 1};
              pointer-events: none;
            `,
            tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center z-40 bg-black`
          ]}
        >
          <div
            css={[
              css`
                ${A.Keyframes(
                  `appear`,
                  `0.75s ${A.EASING_CUBIC} forwards`,
                  `0.3s`
                )}
                -webkit-perspective: 1000;
                -webkit-font-smoothing: subpixel-antialiased;
                backface-visibility: hidden;
              `,
              tw`flex md:block items-center justify-center`
            ]}
          >
            <Logo.Wordmark
              color="white"
              styles={[tw`w-5/6 md:w-auto md:h-8 mb-8`]}
            />
          </div>

          <div
            css={[
              css`
                ${A.Keyframes(`spin`, `3s ${A.EASING_CUBIC} infinite`, `2s`)}
              `,
              tw`w-20 md:w-24 flex items-center justify-center`
            ]}
          >
            <div
              css={[
                css`
                  ${A.Keyframes(
                    `appear`,
                    `1s ${A.EASING_CUBIC} forwards`,
                    `1s`
                  )}
                `
              ]}
            >
              <Icon.Round styles={[tw`w-full`]} />
            </div>
          </div>
        </div>

        {/* // */}

        {/* // swiping movement to hide filters // */}
        {isDesktop() && runSearch && (
          <div
            id="swiper"
            css={[
              css`
                @keyframes swipeScreen {
                  0% {
                    transform: translate3d(0, 100vh, 0);
                  }
                  25% {
                    transform: translate3d(0, 0, 0);
                  }
                  75% {
                    transform: translate3d(0, 0, 0);
                  }
                  100% {
                    transform: translate3d(0, -100vh, 0);
                  }
                }

                animation: swipeScreen ${SEARCH_TIMEOUT}ms ${A.EASING_CUBIC}
                  forwards;
                transform: translate3d(0, 100vh, 0);
              `,
              tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30 flex items-center justify-center pointer-events-none bg-koamaru`
            ]}
          >
            <div
              css={[
                tw`w-12 h-12 relative flex items-center justify-center mx-2`
              ]}
            >
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appear`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `0.25s`
                    )}
                  `
                ]}
              >
                <Icon.Round styles={[tw`w-12 h-12`]} />
              </div>
            </div>
            <div
              css={[
                tw`w-12 h-12 relative flex items-center justify-center mx-2`
              ]}
            >
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appear`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `0.5s`
                    )}
                  `
                ]}
              >
                <Icon.Emerald styles={[tw`w-12 h-12`]} />
              </div>
            </div>
            <div
              css={[
                tw`w-12 h-12 relative flex items-center justify-center mx-2`
              ]}
            >
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appear`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `0.75s`
                    )}
                  `
                ]}
              >
                <Icon.Cushion styles={[tw`w-12 h-12`]} />
              </div>
            </div>
            <div
              css={[
                tw`w-12 h-12 relative flex items-center justify-center mx-2`
              ]}
            >
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appear`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `1s`
                    )}
                  `
                ]}
              >
                <Icon.Pear styles={[tw`w-12 h-12`]} />
              </div>
            </div>
          </div>
        )}

        {(gridVisible && isDesktop() && (
          <>
            <div
              id="filter-bar"
              css={[
                css`
                  ${A.Keyframes(
                    `appearDown`,
                    `0.5s ${A.EASING_CUBIC} forwards`,
                    `0.3s`
                  )}
                `,
                tw`w-screen sticky top-10 right-0 bottom-0 left-0 z-20 hidden md:block`
              ]}
            >
              {/* // ------------------------------------------------------------ // */}
              {/* // insta-filters // */}

              <div
                css={[
                  css`
                    //
                  `,
                  tw`w-full h-20 relative z-20 flex items-center justify-between px-3 bg-koamaru border-b text-white`
                ]}
              >
                {/* // primary // */}
                {(!advancedVisible && (
                  <>
                    <div
                      css={[
                        css`
                          ${A.Keyframes(
                            `appearLeft`,
                            `0.5s ${A.EASING_CUBIC} forwards`
                          )}
                        `,
                        tw`h-full relative flex items-center`
                      ]}
                    >
                      <T.Body font="2" styles={[tw`mr-4`]}>
                        Type:
                      </T.Body>

                      <div css={[tw`h-full relative flex items-center`]}>
                        {primaryFilter?.values?.[0] &&
                          primaryFilter.values.map((value, valueIndex) => {
                            const { key } = primaryFilter;
                            const active =
                              filters?.[primaryFilter?.key]?.toLowerCase() ===
                              value.toLowerCase();

                            const filterButtonCSS = css`
                              @media screen and (min-width: 1025px) {
                                width: 7rem;
                              }

                              @media screen and (min-width: 1280px) {
                                width: 8rem;
                              }

                              background: transparent;
                              color: ${theme`colors.white`};

                              border-top: 1px solid ${theme`colors.white`};
                              border-bottom: 1px solid ${theme`colors.white`};
                              border-left: 1px solid ${theme`colors.white`};

                              &.selected {
                                background: ${theme`colors.white`};
                                color: ${theme`colors.koamaru`};
                              }

                              &:hover {
                                @media screen and (min-width: 1025px) {
                                  background: ${theme`colors.white`};
                                  color: ${theme`colors.koamaru`};
                                }
                              }
                            `;

                            return (
                              <button
                                key={`sticky-filter-type-${value}`}
                                type="button"
                                css={[
                                  css`
                                    ${filterButtonCSS}

                                    border-right: 1px solid
                                      ${valueIndex ===
                                    primaryFilter.values.length - 1
                                      ? theme`colors.white`
                                      : `transparent`};

                                    background: ${active
                                      ? theme`colors.white`
                                      : theme`colors.koamaru`};
                                    color: ${active
                                      ? theme`colors.koamaru`
                                      : theme`colors.white`};
                                  `,
                                  tw`h-12 relative block text-center`
                                ]}
                                onClick={() => {
                                  setActiveFilterTray(null);
                                  replaceFilter(key, value);
                                  // toggleFilter(key, value);
                                }}
                              >
                                <T.Caption styles={[tw`block`]}>
                                  {value.toUpperCase()}
                                </T.Caption>
                              </button>
                            );
                          })}
                      </div>

                      {/* // secondary // */}
                      <div css={[tw`h-full relative flex items-center`]}>
                        <div
                          css={[
                            css`
                              @media screen and (min-width: 1025px) {
                                margin-left: 1rem;
                                margin-right: 1rem;
                              }

                              @media screen and (min-width: 1280px) {
                                margin-left: 3rem;
                                margin-right: 3rem;
                              }
                            `
                          ]}
                        >
                          {trayLoading && (
                            <div
                              css={[
                                css`
                                  ${A.Keyframes(
                                    `appear`,
                                    `0.15s ${A.EASING_CUBIC} forwards`
                                  )}
                                `,
                                tw`flex items-center`
                              ]}
                            >
                              <T.Caption styles={[tw`block`]}>
                                Resetting filters...
                              </T.Caption>

                              <div
                                css={[
                                  css`
                                    ${A.Keyframes(
                                      `spin`,
                                      `2.5s ${A.EASING_CUBIC} infinite`,
                                      `0`
                                    )}
                                  `,
                                  tw`w-3 ml-2 flex items-center justify-center`
                                ]}
                              >
                                <div>
                                  <Icon.Emerald styles={[tw`w-full`]} />
                                </div>
                              </div>
                            </div>
                          )}

                          {!trayLoading && (
                            <div
                              css={[
                                css`
                                  ${A.Keyframes(
                                    `appear`,
                                    `0.15s ${A.EASING_CUBIC} forwards`
                                  )}
                                `
                              ]}
                            >
                              <T.Caption styles={[tw`block`]}>
                                Refine by:
                              </T.Caption>
                            </div>
                          )}
                        </div>

                        {!trayLoading && (
                          <div css={[tw`h-full relative flex items-center`]}>
                            {secondaryFilters.map((filter) => (
                              <div
                                key={`sticky-filter-${filter.key}`}
                                css={[tw`relative`]}
                              >
                                <button
                                  type="button"
                                  css={[
                                    css`
                                      @media screen and (min-width: 1025px) {
                                        padding-right: 1.25rem;
                                        padding-left: 1.25rem;
                                      }

                                      @media screen and (min-width: 1280px) {
                                        padding-right: 2rem;
                                        padding-left: 2rem;
                                      }
                                    `,
                                    tw`w-full h-full relative block pt-4 pb-4`
                                  ]}
                                  onClick={() =>
                                    setActiveFilterTray(
                                      activeFilterTray === filter.key
                                        ? null
                                        : filter.key
                                    )
                                  }
                                >
                                  <div
                                    css={[
                                      css`
                                        transition: opacity 0.2s
                                          ${A.EASING_CUBIC};

                                        top: 50%;
                                        left: 0.5rem;
                                        transform: translate3d(0, -50%, 0);
                                        opacity: ${hasValue(filter) ? 1 : 0};
                                      `,
                                      tw`w-2 h-2 absolute rounded-full bg-white`
                                    ]}
                                  />

                                  <T.Caption font="2" styles={[tw`block`]}>
                                    {filter.key.toUpperCase()}
                                  </T.Caption>
                                </button>

                                {getWidgetJSX(filter)}
                              </div>
                            ))}

                            {tertiaryFilters.map((filter, filterIndex) => {
                              if (filterIndex > 2) {
                                return (
                                  <React.Fragment
                                    key={`sticky-tertiary-dummy-${filter.key}`}
                                  />
                                );
                              }

                              return (
                                <div
                                  key={`sticky-filter-${filter.key}`}
                                  css={[tw`relative`]}
                                >
                                  <button
                                    type="button"
                                    css={[
                                      css`
                                        @media screen and (min-width: 1025px) {
                                          padding-right: 1.25rem;
                                          padding-left: 1.25rem;
                                        }

                                        @media screen and (min-width: 1280px) {
                                          padding-right: 2rem;
                                          padding-left: 2rem;
                                        }
                                      `,
                                      tw`w-full h-full relative block pt-4 pb-4`
                                    ]}
                                    onClick={() =>
                                      setActiveFilterTray(
                                        activeFilterTray === filter.key
                                          ? null
                                          : filter.key
                                      )
                                    }
                                  >
                                    <div
                                      css={[
                                        css`
                                          transition: opacity 0.2s
                                            ${A.EASING_CUBIC};

                                          top: 50%;
                                          left: 0.5rem;
                                          transform: translate3d(0, -50%, 0);
                                          opacity: ${hasValue(filter) ? 1 : 0};
                                        `,
                                        tw`w-2 h-2 absolute rounded-full bg-white`
                                      ]}
                                    />

                                    <T.Caption font="2" styles={[tw`block`]}>
                                      {filter.key.toUpperCase()}
                                    </T.Caption>
                                  </button>

                                  {getWidgetJSX(filter)}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      type="button"
                      css={[
                        css`
                          &:hover {
                            @media screen and (min-width: 1025px) {
                              text-decoration: underline;
                            }
                          }
                        `,
                        tw`p-2`
                      ]}
                      onClick={() => {
                        setActiveFilterTray(null);
                        setAdvancedVisible(true);
                      }}
                    >
                      <div tw="flex items-center justify-center">
                        <T.Caption styles={[tw`block`]}>MORE FILTERS</T.Caption>
                        <Icon.Arrow color="white" styles={[tw`w-2 ml-2`]} />
                      </div>
                    </button>
                  </>
                )) || (
                  <>
                    <div
                      css={[
                        css`
                          ${A.Keyframes(
                            `appearRight`,
                            `0.5s ${A.EASING_CUBIC} forwards`
                          )}
                        `,
                        tw`h-full relative flex items-center`
                      ]}
                    >
                      <button
                        type="button"
                        css={[
                          css`
                            &:hover {
                              @media screen and (min-width: 1025px) {
                                text-decoration: underline;
                              }
                            }
                          `,
                          tw`p-2`
                        ]}
                        onClick={() => {
                          setActiveFilterTray(null);
                          setAdvancedVisible(false);
                        }}
                      >
                        <div tw="flex items-center justify-center">
                          <Icon.ArrowLeft
                            color="white"
                            styles={[tw`w-2 mr-2`]}
                          />
                          <T.Caption styles={[tw`block`]}>BACK</T.Caption>
                        </div>
                      </button>

                      {/* // secondary // */}
                      <div css={[tw`h-full relative flex items-center`]}>
                        <div
                          css={[
                            css`
                              @media screen and (min-width: 1025px) {
                                margin-left: 1rem;
                                margin-right: 1rem;
                              }

                              @media screen and (min-width: 1280px) {
                                margin-left: 3rem;
                                margin-right: 1.5rem;
                              }
                            `
                          ]}
                        >
                          <T.Caption styles={[tw`block`]}>Refine by:</T.Caption>
                        </div>

                        <div css={[tw`h-full relative flex items-center`]}>
                          {tertiaryFilters.map((filter, filterIndex) => {
                            if (filterIndex <= 2) {
                              return (
                                <React.Fragment
                                  key={`sticky-tertiary-dummy-${filter.key}`}
                                />
                              );
                            }

                            //

                            return (
                              <div
                                key={`sticky-filter-${filter.key}`}
                                css={[tw`relative`]}
                              >
                                <button
                                  type="button"
                                  css={[
                                    css`
                                      @media screen and (min-width: 1025px) {
                                        padding-right: 1.25rem;
                                        padding-left: 1.25rem;
                                      }

                                      @media screen and (min-width: 1280px) {
                                        padding-right: 2rem;
                                        padding-left: 2rem;
                                      }
                                    `,
                                    tw`w-full h-full relative block pt-4 pb-4`
                                  ]}
                                  onClick={() =>
                                    setActiveFilterTray(
                                      activeFilterTray === filter.key
                                        ? null
                                        : filter.key
                                    )
                                  }
                                >
                                  <div
                                    css={[
                                      css`
                                        transition: opacity 0.2s
                                          ${A.EASING_CUBIC};

                                        top: 50%;
                                        left: 0.5rem;
                                        transform: translate3d(0, -50%, 0);
                                        opacity: ${hasValue(filter) ? 1 : 0};
                                      `,
                                      tw`w-2 h-2 absolute rounded-full bg-white`
                                    ]}
                                  />

                                  <T.Caption font="2" styles={[tw`block`]}>
                                    {filter.key.toUpperCase()}
                                  </T.Caption>
                                </button>

                                {getWidgetJSX(
                                  filter,
                                  filterIndex === 3 ? `100px` : 0
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* // ------------------------------------------------------------ // */}
              {/* // result count / sorting // */}

              <div
                css={[
                  css`
                    //
                  `,
                  tw`w-full h-10 relative z-10 flex items-center justify-between px-3 bg-white border-b text-koamaru`
                ]}
              >
                <header tw="flex items-center">
                  <button
                    type="button"
                    css={[
                      css`
                        &:hover {
                          @media screen and (min-width: 1025px) {
                            text-decoration: underline;
                          }
                        }
                      `,
                      tw`py-2 px-4`
                    ]}
                    onClick={() => {
                      setGridRefreshing(true);
                      reset();
                    }}
                  >
                    <T.Heading font="b2" level="3" serif>
                      Reset Filters
                    </T.Heading>
                  </button>

                  {resultCount && (
                    <T.Heading
                      font="b2"
                      inject={{ tw: tw`ml-4` }}
                      level="3"
                      serif
                    >
                      {resultCount < 99
                        ? `${resultCount} Results`
                        : `Over 100 diamonds match your search, try narrowing your search using the filters.`}
                    </T.Heading>
                  )}
                </header>

                <select
                  onChange={(e) => {
                    setGridRefreshing(true);

                    setSorting(e.currentTarget.value);
                  }}
                  css={[
                    css`
                      // -webkit-appearance: none;

                      width: 10rem;
                      font-family: ${theme`fontFamily`.body.join()};
                      font-size: 11px;
                      line-height: 13px;
                    `,
                    tw`h-6 relative block cursor-pointer`
                  ]}
                  default="price-low-high"
                >
                  <option value="price-low-high">
                    Sort by: Price (Low – High)
                  </option>
                  <option value="price-high-low">
                    Sort by: Price (High – Low)
                  </option>
                  <option value="total-score">Sort by: Total Score</option>
                  <option value="size-score">Sort by: Size Score</option>
                </select>
              </div>
            </div>

            {/* // ------------------------------------------------------------ // */}
            {/* // core results grid (desktop) // */}

            <DiamondGrid
              filters={activeFilters}
              loaded={loaded}
              rendering={gridRefreshing}
              reset={reset}
              sorting={sorting}
            />
          </>
        )) || (
          <>
            <section
              css={[
                css`
                  height: 100vw;

                  @media screen and (min-width: 544px) {
                    height: 500px;
                  }

                  // @media screen and (min-width: 769px) {
                  //   height: 500px;
                  // }
                `,
                tw`w-full relative overflow-hidden bg-black`
              ]}
            >
              {loaded && (
                <Grid
                  node="header"
                  styles={[tw`h-full relative z-20 items-start`]}
                >
                  <div
                    css={[
                      css`
                        ${A.Keyframes(
                          `appear`,
                          `1.5s ${A.EASING_CUBIC} forwards`,
                          `0.3s`
                        )}
                      `,
                      tw`col-span-20 md:col-span-13 col-start-3 md:col-start-7 h-full relative flex flex-col items-center justify-center pt-16 text-white uppercase`
                    ]}
                  >
                    <T.Heading
                      font="3"
                      level="1"
                      serif
                      styles={[tw`w-full text-left`]}
                    >
                      Diamonds are the
                      <br /> Hardest thing on
                      <br tw="md:hidden" /> earth
                    </T.Heading>

                    <T.Heading
                      font="3"
                      level="2"
                      styles={[tw`w-full text-right`]}
                    >
                      Now they’re a <br />
                      little easier
                    </T.Heading>

                    <div css={[tw`w-full sm:w-auto relative mt-10 border`]}>
                      <Button
                        color="white"
                        onClick={() => {
                          setRunSearch(true);

                          if (
                            !isDesktop() &&
                            window &&
                            mobileGridRef?.current
                          ) {
                            applyFilters();

                            setTimeout(() => {
                              const top = mobileGridRef.current.offsetTop - 64;

                              window.scrollTo({
                                top,
                                behavior: `smooth`
                              });
                            }, 100);
                          }
                        }}
                        styles={[tw`w-full sm:w-auto sm:px-12`]}
                        text="SEE ALL DIAMONDS"
                      />
                    </div>
                  </div>
                </Grid>
              )}

              <div tw="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10">
                <Video
                  css={[
                    css`
                      z-index: -1;
                    `,
                    tw`w-full h-full relative block object-cover`
                  ]}
                  src="https://player.vimeo.com/external/299583667.hd.mp4?s=3f170624a3c03260f920b06e631576fe1c2c4af9&profile_id=175"
                />
              </div>
            </section>

            <div
              css={[
                tw`w-full relative bg-black border-t border-b border-silver text-white`
              ]}
            >
              <Grid node="section" styles={[tw`pt-8 pb-12`]}>
                <T.Heading
                  font={isDesktop() ? `4` : `b1`}
                  level="3"
                  serif
                  styles={[
                    tw`col-span-18 md:col-span-9 col-start-3 md:col-start-3 relative uppercase`
                  ]}
                >
                  Step 1: The Foundations
                </T.Heading>

                <T.Body
                  font={isDesktop() ? `1` : `2`}
                  styles={[
                    tw`col-span-18 md:col-span-8 col-start-3 md:col-start-15 relative mt-6 md:mt-0`
                  ]}
                >
                  Natural, Lab-grown or Moissanite?
                  <br />
                  This fundamental question will have the biggest impact on
                  price.
                </T.Body>
              </Grid>

              <Grid node="section" styles={[tw``]}>
                <div
                  css={[
                    css`
                      transition: background 0.3s ${A.EASING_CUBIC};

                      padding-right: calc((100vw / 24) * 1);
                      padding-left: calc((100vw / 24) * 1);

                      background: ${hasValue(primaryFilter)
                        ? theme`colors.koamaru`
                        : `transparent`};
                    `,
                    tw`col-span-24 md:col-span-22 md:col-start-2  relative border-t border-silver`
                  ]}
                >
                  {getFilterJSX(primaryFilter)}
                </div>
              </Grid>
            </div>

            <div
              css={[
                tw`w-full relative bg-black border-b border-silver text-white`
              ]}
            >
              <Grid node="section" styles={[tw`pt-8 pb-12`]}>
                <T.Heading
                  font={isDesktop() ? `4` : `b1`}
                  level="3"
                  serif
                  styles={[
                    tw`col-span-18 md:col-span-9 col-start-3 md:col-start-3 relative uppercase`
                  ]}
                >
                  STEP 2: THE BIG PICTURE
                </T.Heading>

                <T.Body
                  font={isDesktop() ? `1` : `2`}
                  styles={[
                    tw`col-span-18 md:col-span-8 col-start-3 md:col-start-15 relative mt-6 md:mt-0`
                  ]}
                >
                  Price, carat and shape are typically the next biggest
                  decisions to make. Make sure you start with a broader range
                  and filter down later. You can also select more than one shape
                  if you’re not yet sure.
                </T.Body>
              </Grid>

              <Grid node="section" styles={[tw``]}>
                <ul
                  css={[
                    css`
                      //
                    `,
                    tw`col-span-24 md:col-span-22 md:col-start-2 relative md:flex md:flex-wrap border-t border-silver`
                  ]}
                >
                  {secondaryFilters.map(
                    (secondaryFilter, secondaryFilterIndex) => (
                      <li
                        key={secondaryFilter.key}
                        css={[
                          css`
                            transition: background 0.3s ${A.EASING_CUBIC};

                            padding-right: calc((100vw / 24) * 1);
                            padding-left: calc((100vw / 24) * 1);

                            width: ${secondaryFilterIndex < 2 && isDesktop()
                              ? `50`
                              : `100`}%;
                            border-top: 1px solid
                              ${secondaryFilterIndex >= 2 ||
                              (!isDesktop() && secondaryFilter?.key === `carat`)
                                ? theme`colors.silver`
                                : `transparent`};
                            border-right: 1px solid
                              ${secondaryFilterIndex === 0 && isDesktop()
                                ? theme`colors.silver`
                                : `transparent`};

                            background: ${hasValue(secondaryFilter)
                              ? theme`colors.koamaru`
                              : `transparent`};
                          `
                        ]}
                      >
                        {getFilterJSX(secondaryFilter)}
                      </li>
                    )
                  )}
                </ul>
              </Grid>
            </div>

            <div
              ref={scrollThresholdRef}
              css={[
                tw`w-full relative bg-black border-b border-silver text-white`
              ]}
            >
              <Grid node="section" styles={[tw`pt-8 pb-12`]}>
                <T.Heading
                  font={isDesktop() ? `4` : `b1`}
                  level="3"
                  serif
                  styles={[
                    tw`col-span-18 md:col-span-9 col-start-3 md:col-start-3 relative uppercase`
                  ]}
                >
                  STEP 3: Details, Details, Details
                </T.Heading>

                <div
                  css={[
                    tw`col-span-18 md:col-span-8 col-start-3 md:col-start-15 relative mt-6 md:mt-0`
                  ]}
                >
                  <T.Body font={isDesktop() ? `1` : `2`}>
                    Let’s be honest, all of the following is very much the fine
                    print of the diamond world. If you’re at all not sure,
                    better to leave these blank for now. Of course, if you want
                    further help, you can always{` `}
                    <a
                      href="https://frankdarling.com/contact"
                      rel="noopener noreferrer"
                      target="_blank"
                      tw="underline"
                    >
                      get in touch.
                    </a>
                  </T.Body>

                  <button
                    type="button"
                    css={[tw`mt-10 mb-10 underline`]}
                    onClick={() => setFiltersOpen(!filtersOpen)}
                  >
                    <T.Heading font="5" level="2">
                      {filtersOpen ? `CLOSE` : `OPEN`} FILTERS
                    </T.Heading>
                  </button>
                </div>
              </Grid>
            </div>

            {filtersOpen && (
              <>
                <div css={[tw`w-full relative bg-black`]}>
                  <Grid node="section" styles={[tw``]}>
                    <ul
                      css={[
                        css`
                          //
                        `,
                        tw`col-span-24 md:col-span-22 md:col-start-2 relative md:flex md:flex-wrap`
                      ]}
                    >
                      {tertiaryFilters.map(
                        (tertiaryFilter, tertiaryFilterIndex) => (
                          <li
                            key={tertiaryFilter.key}
                            css={[
                              css`
                                transition: background 0.3s ${A.EASING_CUBIC};

                                padding-right: calc((100vw / 24) * 1);
                                padding-left: calc((100vw / 24) * 1);

                                width: ${isDesktop() &&
                                tertiaryFilter?.align !== `full`
                                  ? `50`
                                  : `100`}%;

                                border-top: 1px solid
                                  ${tertiaryFilterIndex > 0
                                    ? theme`colors.silver`
                                    : `transparent`};

                                background: ${hasValue(tertiaryFilter)
                                  ? theme`colors.koamaru`
                                  : `transparent`};
                              `
                            ]}
                          >
                            {getFilterJSX(tertiaryFilter)}
                          </li>
                        )
                      )}
                    </ul>
                  </Grid>
                </div>

                <div css={[tw`w-full relative bg-white`]}>
                  <Grid node="section">
                    <ul
                      css={[
                        css`
                          //
                        `,
                        tw`col-span-24 md:col-span-20 md:col-start-3 relative md:flex md:items-stretch`
                      ]}
                    >
                      {specialFilters.map((specialFilter) => {
                        const filterButtonCSS = css`
                          background: ${theme`colors.white`};
                          color: ${theme`colors.koamaru`};

                          border-top: 1px solid ${theme`colors.koamaru`};
                          border-bottom: 1px solid ${theme`colors.koamaru`};
                          border-left: 1px solid ${theme`colors.koamaru`};

                          &:hover {
                            @media screen and (min-width: 1025px) {
                              background: ${theme`colors.koamaru`};
                              color: ${theme`colors.white`};
                            }
                          }
                        `;

                        let inPersonFilter;
                        let supplierFilter;

                        if (availableFilters?.[`see in person`]?.values?.[0]) {
                          inPersonFilter = availableFilters[`see in person`];
                        }

                        if (
                          availableFilters?.[`supplier standards`]?.values?.[0]
                        ) {
                          supplierFilter =
                            availableFilters[`supplier standards`];
                        }

                        let filterJSX;

                        switch (specialFilter.key) {
                          case `see in person`:
                            filterJSX = (
                              <article
                                css={[
                                  css`
                                    padding-right: ${isDesktop()
                                      ? `0`
                                      : `calc((100vw / 24) * 2)`};
                                    padding-left: ${isDesktop()
                                      ? `0`
                                      : `calc((100vw / 24) * 2)`};
                                  `,
                                  tw`w-full relative flex flex-col md:flex-row items-end md:items-stretch overflow-hidden`
                                ]}
                              >
                                <div
                                  css={[
                                    tw`w-full md:w-2/5 relative flex flex-col justify-between pt-4 md:pb-4 text-koamaru`
                                  ]}
                                >
                                  <T.Heading font="b2" level="4">
                                    SEE IT IN PERSON
                                  </T.Heading>

                                  <div css={[tw`mt-8 md:mt-0`]}>
                                    <T.Body font="2" styles={[tw`mb-2`]}>
                                      Want to see your stone IRL? Many stones
                                      are available to see in person at our
                                      showroom in Williamsburg, Brooklyn or over
                                      video chat. Select the filter below to
                                      browse then{` `}
                                      <a
                                        href="https://consult.frankdarling.com/book/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="underline"
                                      >
                                        book an appointment
                                      </a>
                                      {` `}
                                      with us.
                                    </T.Body>
                                  </div>

                                  <div
                                    css={[
                                      tw`w-full relative flex mt-8 md:mt-0`
                                    ]}
                                  >
                                    {inPersonFilter.values.map(
                                      (value, valueIndex) => {
                                        const key = `in-person-${value}`;

                                        const active =
                                          filters?.[inPersonFilter?.key] &&
                                          filters[inPersonFilter.key] === value;

                                        return (
                                          <button
                                            key={key}
                                            type="button"
                                            onClick={() => {
                                              replaceFilter(
                                                inPersonFilter?.key,
                                                value
                                              );
                                            }}
                                            css={[
                                              css`
                                                ${filterButtonCSS}

                                                color: ${active
                                                  ? theme`colors.white`
                                                  : theme`colors.koamaru`};
                                                background: ${active
                                                  ? theme`colors.koamaru`
                                                  : theme`colors.white`};

                                                border-right: 1px solid
                                                  ${valueIndex ===
                                                  inPersonFilter.values.length -
                                                    1
                                                    ? theme`colors.koamaru`
                                                    : `transparent`};
                                              `,
                                              tw`w-1/2 h-12 relative block text-center`
                                            ]}
                                          >
                                            <T.Body font="2">{value}</T.Body>
                                          </button>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <figure
                                  css={[
                                    tw`w-3/5 md:w-3/5 md:h-full relative -mr-6 md:mr-0 pt-4 flex md:block items-end`
                                  ]}
                                >
                                  <img
                                    css={[
                                      tw`w-full md:w-auto md:h-full relative block`
                                    ]}
                                    src={ladyMirror}
                                    alt="Lady next to a mirror"
                                  />
                                </figure>
                              </article>
                            );

                            break;

                          case `supplier standards`:
                            filterJSX = (
                              <article
                                css={[
                                  css`
                                    padding-right: ${isDesktop()
                                      ? `0`
                                      : `calc((100vw / 24) * 2)`};
                                    padding-left: ${isDesktop()
                                      ? `0`
                                      : `calc((100vw / 24) * 2)`};
                                  `,
                                  tw`w-full h-full relative flex items-stretch pb-32 md:pb-0`
                                ]}
                              >
                                <div
                                  css={[
                                    tw`w-full relative z-10 flex flex-col justify-between pt-4 md:pb-4 text-koamaru`
                                  ]}
                                >
                                  <div
                                    css={[tw`h-5 relative flex items-center`]}
                                  >
                                    <T.Heading
                                      font="b2"
                                      level="4"
                                      styles={[tw`block uppercase`]}
                                    >
                                      SUPPLIER STANDARDS
                                    </T.Heading>

                                    <button
                                      type="button"
                                      css={[
                                        tw`w-5 h-5 absolute top-0 left-0 block -ml-6`
                                      ]}
                                      onClick={() => {
                                        setTip({
                                          ...supplierFilter.tip,
                                          title: `Supplier Standards`
                                        });
                                        setTipActive(true);
                                      }}
                                    >
                                      <div tw="w-full h-full flex items-center justify-center">
                                        <Icon.Tip
                                          color="#1c1794"
                                          styles={[tw`h-3`]}
                                        />
                                      </div>
                                    </button>
                                  </div>

                                  <div
                                    css={[
                                      tw`w-full md:w-1/2 relative mt-8 md:mt-0 mb-8 md:mb-0 md:pr-6`
                                    ]}
                                  >
                                    <T.Body font="2" styles={[tw`mb-2`]}>
                                      All our suppliers are certified
                                      conflict-free. But, we hold them to even
                                      higher standards.
                                    </T.Body>

                                    <T.Body font="2" styles={[tw`mb-2`]}>
                                      Each supplier receives a grade from us
                                      based on their level of traceability and
                                      sustainability. The higher the better.
                                    </T.Body>
                                  </div>

                                  <div css={[tw`w-full relative flex`]}>
                                    {supplierFilter.values.map(
                                      (standard, standardIndex) => {
                                        const key = standard;

                                        const active =
                                          filters?.[supplierFilter.key]?.[0] &&
                                          filters[supplierFilter.key].includes(
                                            standard
                                          );

                                        return (
                                          <button
                                            key={key}
                                            type="button"
                                            onClick={() => {
                                              toggleFilter(
                                                supplierFilter?.key,
                                                standard
                                              );
                                            }}
                                            css={[
                                              css`
                                                ${filterButtonCSS}

                                                color: ${active
                                                  ? theme`colors.white`
                                                  : theme`colors.koamaru`};
                                                background: ${active
                                                  ? theme`colors.koamaru`
                                                  : theme`colors.white`};

                                                border-right: 1px solid
                                                  ${standardIndex ===
                                                  supplierFilter.values.length -
                                                    1
                                                    ? theme`colors.koamaru`
                                                    : `transparent`};
                                              `,
                                              tw`w-1/2 h-12 relative block text-center`
                                            ]}
                                          >
                                            <T.Body font="2">{standard}</T.Body>
                                          </button>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <figure
                                  css={[
                                    tw`w-1/3 md:h-full absolute right-0 top-0 bottom-0 flex items-end md:justify-end md:pt-4 pointer-events-none`
                                  ]}
                                >
                                  <img
                                    css={[
                                      tw`w-full md:w-auto md:h-full relative block md:-mr-20`
                                    ]}
                                    src={leaf}
                                    alt="Leaf on a plant"
                                  />
                                </figure>
                              </article>
                            );

                            break;

                          default:
                            break;
                        }

                        return (
                          <li
                            key={specialFilter.key}
                            css={[
                              css`
                                width: ${isDesktop() ? `50` : `100`}%;
                                border-top: 1px solid
                                  ${isDesktop()
                                    ? `transparent`
                                    : theme`colors.silver`};

                                padding-left: ${specialFilter?.key ===
                                  `supplier standards` && isDesktop()
                                  ? `calc(100vw / 24)`
                                  : `0`};
                              `
                            ]}
                          >
                            {filterJSX}
                          </li>
                        );
                      })}
                    </ul>
                  </Grid>
                </div>
              </>
            )}

            {/* // */}

            <div
              id="instasearch"
              ref={instasearchRef}
              css={[
                tw`w-full sticky md:fixed bottom-0 right-0 left-0 z-20 pt-3 md:pt-6 pb-3 md:pb-6 bg-koamaru text-white`
              ]}
            >
              <Grid styles={[tw`items-center`]}>
                <article css={[tw`hidden md:block col-span-6 col-start-3`]}>
                  <T.Heading font="4" level="2" serif>
                    SEARCH AT ANY TIME, DARLING
                  </T.Heading>

                  <T.Body font="2" styles={[tw`mt-2`]}>
                    Our database filters through over 30,000 ethical diamonds
                    worldwide. But don’t worry, there are plenty of chances to
                    refine your results. So, always best to start your search a
                    little wider.
                  </T.Body>
                </article>

                <div
                  css={[
                    tw`col-span-22 md:col-span-5 col-start-2 md:col-start-18`
                  ]}
                >
                  <Button
                    color="white"
                    onClick={() => {
                      setRunSearch(true);

                      if (!isDesktop() && window && mobileGridRef?.current) {
                        applyFilters();

                        setTimeout(() => {
                          const top = mobileGridRef.current.offsetTop - 64;

                          window.scrollTo({
                            top,
                            behavior: `smooth`
                          });
                        }, 100);
                      }
                    }}
                    styles={[tw`w-full`]}
                    text="SEARCH DIAMONDS"
                  />
                </div>
              </Grid>
            </div>

            {/* // ------------------------------------------------------------ // */}
            {/* // core results grid (mobile) // */}

            <div ref={mobileGridRef} tw="w-full relative">
              {gridVisible && !isDesktop() && (
                <>
                  <div
                    css={[
                      css`
                        top: 64px;
                      `,
                      tw`w-full sticky right-0 left-0 z-20`
                    ]}
                  >
                    <div
                      css={[
                        tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-koamaru`
                      ]}
                    />

                    <Grid>
                      <header
                        css={[
                          tw`col-span-22 col-start-2 relative z-10 pt-2 pb-2 flex items-center justify-between text-white`
                        ]}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (typeof window !== `undefined`) {
                              window.scrollTo({
                                top: 0,
                                behavior: `smooth`
                              });
                            }
                          }}
                        >
                          <div
                            css={[tw`relative flex items-center justify-start`]}
                          >
                            <Icon.Arrow
                              color="white"
                              styles={[
                                css`
                                  transition: transform 0.3s ${A.EASING_CUBIC};
                                  transform: rotate(270deg);
                                  pointer-events: none !important;
                                `,
                                tw`w-1.5 mr-3`
                              ]}
                            />

                            <T.Caption>ADJUST FILTERS</T.Caption>
                          </div>
                        </button>

                        <select
                          onChange={(e) => {
                            setSorting(e.currentTarget.value);
                          }}
                          css={[
                            css`
                              width: 7rem;
                              font-family: ${theme`fontFamily`.body.join()};
                              font-size: 11px;
                              line-height: 13px;
                              background: transparent;
                            `,
                            tw`h-6 relative block z-10 text-white`
                          ]}
                          default="price-low-high"
                        >
                          <option value="price-low-high">
                            Price (Low – High)
                          </option>
                          <option value="price-high-low">
                            Price (High – Low)
                          </option>
                          <option value="total-score">Total Score</option>
                          <option value="size-score">Size Score</option>
                        </select>
                      </header>
                    </Grid>
                  </div>

                  <DiamondGrid
                    filters={activeFilters}
                    loaded={loaded}
                    rendering={gridRefreshing}
                    reset={reset}
                    sorting={sorting}
                  />
                </>
              )}
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    sanityIndex {
      title
    }
  }
`;
